@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Open+Sans:ital,wght@0,400;0,700;1,600&family=Poppins&family=Roboto:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f7 !important;
  color: #333 !important;
}

/* * {
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-decoration: none !important;
  list-style: none;
} */

a {
  color: inherit !important;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainbox {
  background-color: #95c2de;
  margin: auto;
  height: 100vh;
  width: 100vw;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.err {
  color: #ffffff;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 5rem;
}

.mainbox p {
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  line-height: 1;
}

.mainbox p span {
  color: #ffffff;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.5rem;

}

a.home-btn {
  padding: 15px 50px;
  background-color: #f2f3f7;
  border-radius: 15px;
  text-decoration: none;
  color: #03545a !important;
}