div button.delete_group {
  font-size: 12px;
  position: relative;
}

button.delete_group::before {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  content: "\F78A";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: bootstrap-icons !important;
  background-color: rgba(133, 0, 0, 0.6);
  border-radius: var(--bs-btn-border-radius);
  top: 0;
  z-index: 100;
  left: 0;
}

button.delete_group:hover::before {
  opacity: 1;
}
