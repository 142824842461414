.login {
  background-color: #a3a7dd;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: #fefefe;
  width: 500px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.1);
}

.logo {
  background-color: #3598fa;
  height: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 0 15px;
  color: #fff;
}

.logo>div {
  width: 50px;
}

.logo img {
  width: 100%;
}

.menu {
  background-color: #fff;
  width: 320px;
  margin-left: 0;
  min-height: calc(100vh - 70px);
  height: 100%;
  visibility: visible;
  transition: margin 0.3s 0s ease-out;
  position: absolute;
  z-index: 99;
}

.menu-items {
  list-style: none;
  margin: 0;
  margin-top: 30px;
  padding: 0;
}

.menu-items>li a {
  padding: 7px 24px;
  display: block;
  color: #434346 !important;
  text-decoration: none;
  font-family: "poppins";
  transition: all 0.3s ease;
  position: relative;
}

.menu-items>li a::after {
  content: "";
  position: absolute;
  background-color: #fff;
  height: 100%;
  width: 5px;
  border-radius: 10px;
  right: 0;
  top: 0;
}

.menu-items>li i {
  color: #808186;
  font-size: 24px;
  transition: all 0.3s ease;
}

.menu-items>li a:hover,
.menu-items .active {
  color: #3598fa !important;
}

.menu-items>li a:hover::after,
a.active::after {
  background-color: #3598fa !important;
}

.menu-items>li a:hover i,
.menu-items .active i {
  color: #3598fa;
}

.close {
  margin-left: -320px !important;
}

.menu-open {
  margin-left: 320px !important;
}

/* stundent sahifasi uchun stillar */
.student-show tr>td:nth-child(2n + 1) {
  font-weight: 900;
  /* background-color: #f0f0f0; */
}

.student-show tr>td:nth-child(2n) {
  color: #6d6875;
  /* background-color: #f0f0f0; */
}

/* Header menu button style */

header {
  background-color: #fff;
  display: flex;
  padding: 20px 30px;
  align-items: center;
}

/* Sectionlar uchun stillar */
.section {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #e4e3e3;
}

.section h2,
.section h3 {
  font-weight: 700;
}

.core-properties {
  width: 40px;
  height: 40px;
  border: none;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.colour {
  color: black;
}

#main-menu-button {
  z-index: 1;
  /* border: none */
}

#main-menu-button div>span {
  display: block;
  position: relative;
  height: 3px;
  width: 20px;
  margin: 5px 0;
  background-color: black;
}

.main {
  /* margin: 30px 50px; */
  transition: margin 0.3s ease-out;
}

.loader-content {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: 100%;
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 175px;
  height: 80px;
  display: block;
  margin: auto;
  background-image: radial-gradient(circle 25px at 25px 25px,
      #fff 100%,
      transparent 0),
    radial-gradient(circle 50px at 50px 50px, #fff 100%, transparent 0),
    radial-gradient(circle 25px at 25px 25px, #fff 100%, transparent 0),
    linear-gradient(#fff 50px, transparent 0);
  background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
}

.loader::before {
  content: "";
  left: 60px;
  bottom: 18px;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ff3d00;
  background-image: radial-gradient(circle 8px at 18px 18px,
      #fff 100%,
      transparent 0),
    radial-gradient(circle 4px at 18px 0px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 0px 18px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 36px 18px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 18px 36px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 30px 5px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 30px 5px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 30px 30px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 5px 30px, #fff 100%, transparent 0),
    radial-gradient(circle 4px at 5px 5px, #fff 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}

.loader::after {
  content: "";
  left: 94px;
  bottom: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff3d00;
  background-image: radial-gradient(circle 5px at 12px 12px,
      #fff 100%,
      transparent 0),
    radial-gradient(circle 2.5px at 12px 0px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 0px 12px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 24px 12px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 12px 24px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 20px 3px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 20px 3px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 20px 20px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 3px 20px, #fff 100%, transparent 0),
    radial-gradient(circle 2.5px at 3px 3px, #fff 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* Step bar style */
.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  height: 4rem;
}

.steps>.step {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.875rem;
  color: #6d6875;
}

.steps>.step:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto;
  background: #ffffff;
  border: 2px solid #e6e6e6;
  color: #e6e6e6;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -4.2rem;
  line-height: 1.9rem;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 1rem;
}

.steps>.step:after {
  content: "";
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.125rem;
  top: 1rem;
  left: 50%;
}

.steps>.step:last-child:after {
  display: none;
}

.steps>.step.is-complete {
  color: #6d6875;
}

.steps>.step.is-complete:before {
  content: "✓";
  color: #fff;
  background: #58bb58;
  border: 2px solid #58bb58;
}

.steps>.step.is-complete:after {
  background: #58bb58;
}

.steps>.step.is-active {
  font-size: 1.5rem;
}

.steps>.step.is-active:before {
  color: #fff;
  border: 2px solid #58bb58;
  background: #58bb58;
  margin-bottom: -4.9rem;
}

.article-text {
  word-wrap: break-word;
}

@media screen and (max-width: 992) {
  .table-department tr {
    display: flex;
    flex-direction: column;
  }
}

/* Add stundent component styles */
.user_img {
  width: 100px;
  height: 134px;
  object-fit: cover;
  border: 1px solid #333;
}

/* Home statistics page */
.statistics .statistics-item {
  font-family: "Poppins", sans-serif;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.statistics-item .stat-title {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.statistics-item .stat-number {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.bg1 {
  background-color: #4a4eca;
}

.bg2 {
  background-color: #dd7672;
}

.bg3 {
  background-color: #1698d3;
}

.bg4 {
  background-color: #50df7b;
}

.bg5 {
  background-color: #6e77e1;
}

.bg6 {
  background-color: #7b91ab;
}

.bg7 {
  background-color: #8e4b22;
}